<template>
  <div v-if="dataResep">
    <b-modal
      id="modal-tambah-resep"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div>
        <b-container fluid>
          <b-row>
            <b-col cols="auto" class="ml-auto text-right">
              <b-card no-body class="p-2">
                <h5>ALERGI PASIEN</h5>
                <b-form-group v-if="poolAlergi.length" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox 
                      v-for="option in poolAlergi"
                      v-model="option.ms_alergi_id"
                      :key="option.ms_alergi_id"
                      :value="option.ms_alergi_id"
                      :aria-describedby="ariaDescribedby"
                      disabled
                      inline
                    >{{ option.nama_alergi }}</b-form-checkbox>
                </b-form-group>
                <p v-else class="mb-0">
                    <i>Pasien tidak memiliki alergi</i>
                </p>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form>
                <b-form-group>
                  <b-form-checkbox
                    v-model="is_racikan"
                    :value="true"
                    :unchecked-value="false"
                    class="mr-n2"
                    @change="inikah()"
                  >
                    Tandai apabila racikan 
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label> Nama Racikan </template>
                  <b-form-input
                    :disabled="!is_racikan"
                    :state="checkIfValid('no_racikan')"
                    v-model="$v.is_data.no_racikan.$model"
                    placeholder="Racikan (e.g 'Obat Demam')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label> Jumlah Racikan </template>
                  <b-form-input
                    :disabled="!is_racikan"
                    :state="checkIfValid('jumlah_bungkus')"
                    v-model="$v.is_data.jumlah_bungkus.$model"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Indikasi Racikan
                  </template>
                  <b-form-input
                    :disabled="!is_racikan"
                    :state="checkIfValid('indikasi_head_racikan')"
                    type="text"
                    v-model="$v.is_data.indikasi_head_racikan.$model"
                    debounce="500"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Signa <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    v-model="$v.is_data.signa_racikan.$model"
                    :options="$store.state.data_static.signa"
                    :state="checkIfValid('signa_racikan')"
                  ></b-form-select>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Aturan Pakai <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    v-model="$v.is_data.aturan_pakai_racikan.$model"
                    :options="$store.state.data_static.aturan_pakai"
                    :state="checkIfValid('aturan_pakai_racikan')"
                  ></b-form-select>
                </b-form-group>

                <hr />

                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Nama Komposisi <span class="text-danger">*</span>
                  </template>
                  <Multiselect
                    v-model="cek_komposisi"
                    :options="option_komposisi"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    track-by="ms_komposisi_barang_id"
                    label="nama_komposisi"
                    placeholder="-- Pilih Komposisi --"
                    size="sm"
                  />
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Nama Obat <span class="text-danger">*</span>
                  </template>
                  <Multiselect
                    v-model="$v.is_data.arr_barang.$model"
                    :state="checkIfValid('arr_barang')"
                    :options="filteredOptions(cek_komposisi)"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    track-by="ms_barang_id"
                    label="nama_barang"
                    placeholder="-- Pilih Obat --"
                    size="sm"
                    :custom-label="namaDanStock"
                    @select="cekAlergi($event)"
                  />
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Jumlah Permintaan <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    :state="checkIfValid('qty_permintaan')"
                    type="number"
                    v-model="$v.is_data.qty_permintaan.$model"
                    debounce="500"
                    @input="inputQtyBarang($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Indikasi Obat
                  </template>
                  <b-form-input
                    :state="checkIfValid('indikasi_racikan')"
                    type="text"
                    v-model="$v.is_data.indikasi_racikan.$model"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Keterangan
                  </template>
                  <b-form-textarea
                    v-model="$v.is_data.keterangan_racikan.$model"
                    :state="checkIfValid('keterangan_racikan')"
                    rows="3"
                    placeholder="Tambah keterangan"
                  ></b-form-textarea>
                </b-form-group>

                <span v-if="is_rendered">
                  <b-form-group class="my-3" >
                    <b-button
                      :disabled="formCheck()"
                      variant="primary"
                      size="sm"
                      @click="addObat()"
                    >
                      Tambah Obat
                    </b-button>
                  </b-form-group>

                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    responsive
                    show-empty
                    small
                    @filtered="onFiltered"
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                    <template #cell(no)="item">
                      {{ item.index + 1 }}
                    </template>
                    <template #cell(actions)="item">
                      <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Racikan'"
                        @click="hapusDataRacikan(item)"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </template>
                  </b-table>
                </span>
                <span v-else></span>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-tambah-resep')">
          Batal
        </b-button>
        <b-button v-if="!is_racikan" variant="primary" :disabled="busy || !isValid" @click="saveData()">
          {{ button }}
        </b-button>
        <b-button v-else variant="primary" :disabled="(busy || !isValid) || !items_length" @click="saveData()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "modalTambahResep",
  props: ["fileName", "option_obat", "option_komposisi", "dataResep", "poolAlergi"],
  data() {
    return {
      is_racikan: false,
      is_data: {
        no_racikan: null,
        jumlah_bungkus: null,
        qty_permintaan: "",
        qty_barang: "",
        signa_racikan: "",
        aturan_pakai_racikan: "",
        keterangan_racikan: "",
        indikasi_head_racikan: "",
        indikasi_racikan: "",
        arr_barang: [],
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      cek_komposisi: null || [],

      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Nama Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_permintaan",
          label: "Jumlah Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "indikasi_racikan",
          label: "Indikasi Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    is_rendered() {
      return this.is_racikan
    },
    items_length() {
      return this.items.length > 1 ? true : false
    }
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      no_racikan: {},
      jumlah_bungkus: {},
      qty_permintaan: {
        required: requiredIf(function () {
          return !this.items.length;
        }),
      },
      qty_barang: {},
      signa_racikan: { required },
      aturan_pakai_racikan: { required },
      keterangan_racikan: {},
      indikasi_head_racikan: {},
      indikasi_racikan: {},
      arr_barang: {},
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkIfValid(fieldName, childField = "") {
      // console.log("fieldname", fieldName);
      const field = this.$v.is_data[fieldName];
      // console.log("field", field)
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    cekKomposisi(ev) {
      console.log(ev);
      // this.filteredOptions(ev.ms_komposisi_barang_id)
    },
    filteredOptions(item) {
      if (item) {
        return this.option_obat.filter(
          (opt) => opt.pool_komposisi.includes(item.nama_komposisi)
        );
      } else {
        return this.option_obat;
      }
    },
    async saveData() {
      let vm = this;
      // console.log(vm.is_racikan);
      // console.log(vm.is_data);
      // vm.busy = true;
      // vm.button = "Mohon Tunggu";
      let addResepData = {
        resep_id: "",
        bulk_racikan: [],
      };
      if (vm.is_racikan) {
        let head_racikan = {
          no_racikan: vm.is_data.no_racikan,
          indikasi_head_racikan: vm.is_data.indikasi_head_racikan,
          jumlah_bungkus: vm.is_data.jumlah_bungkus,
          data_racikan: []
        }
        // vm.is_data.arr_barang.forEach((el) => {
        vm.items.forEach((el) => {
          let x = {};
          // delete x.arr_barang
          x.no_racikan = vm.is_data.no_racikan,
          x.signa_racikan = vm.is_data.signa_racikan;
          x.aturan_pakai_racikan = vm.is_data.aturan_pakai_racikan;
          x.ms_barang_id = el.ms_barang_id;
          x.nama_barang = el.nama_barang;
          x.qty_permintaan = el.qty_permintaan;
          x.qty_barang = el.qty_barang;
          x.indikasi = el.indikasi_racikan
          x.keterangan_racikan = el.keterangan_racikan;
          head_racikan.data_racikan.push(x)
          // addResepData.bulk_racikan.push(x);
        });
        addResepData.bulk_racikan.push(head_racikan)
      }
      // console.log(addResepData.bulk_racikan);

      if (vm.dataResep.resep_id) {
        if (vm.is_racikan) {
          addResepData.resep_id = vm.dataResep.resep_id;
          console.log("racikan", addResepData);

          let res = await vm.$axios.post("/racikan/register_bulk", addResepData);
          // console.log("bulk", res);
          if (res.data.status == 200 && res.data.message == "sukses") {
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENAMBAHKAN RESEP",
              showing: true,
            });
            vm.$emit("addResep")
            vm.$bvModal.hide("modal-tambah-resep");
            vm.resetModal();
          } else {
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "GAGAL MENAMBAHKAN RESEP",
              showing: true,
            });
          }
        } else {
          // vm.is_data.resep_id = vm.dataResep.resep_id;
          // vm.is_data.ms_barang_id = vm.is_data.arr_barang.ms_barang_id;
          addResepData.resep_id = vm.dataResep.resep_id;
          let obj = {
            no_racikan : vm.is_data.no_racikan,
            signa_racikan : vm.is_data.signa_racikan,
            aturan_pakai_racikan : vm.is_data.aturan_pakai_racikan,
            ms_barang_id : vm.is_data.arr_barang.ms_barang_id,
            nama_barang : vm.is_data.arr_barang.nama_barang,
            qty_permintaan : vm.is_data.qty_permintaan,
            qty_barang : vm.is_data.qty_barang,
            indikasi : vm.is_data.indikasi_racikan,
            keterangan_racikan : vm.is_data.keterangan_racikan,
          }
          addResepData.bulk_racikan.push(obj)
          console.log("non", addResepData);


          let res = await vm.$axios.post("/racikan/register_bulk", addResepData);
          // console.log("reg", res);
          if (res.data.status == 200 && res.data.message == "sukses") {
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENAMBAHKAN RESEP",
              showing: true,
            });
            vm.$emit("addResep")
            vm.$bvModal.hide("modal-tambah-resep");
            vm.resetModal();
          } else {
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "GAGAL MENAMBAHKAN RESEP",
              showing: true,
            });
          }
        }

        vm.button = "Simpan";
        vm.busy = false;
      } else {
        if (vm.is_racikan) {
          vm.$emit("addResep", addResepData);
        } else {
          console.log("is_data");
          vm.$emit("addResep", vm.is_data);
        }
        vm.button = "Simpan";
        vm.busy = false;
        vm.$bvModal.hide("modal-resep");
        vm.resetModal();
      }
    },
    inputQtyBarang(val) {
      if (!this.is_racikan) {
        this.is_data.qty_barang = val;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    customLabel({ nama_barang, kode_batch }) {
      return `${nama_barang} — [Batch: ${kode_batch}]`;
    },
    addObat() {
      let x = {
        nama_barang: this.is_data.arr_barang.nama_barang,
        ms_barang_id: this.is_data.arr_barang.ms_barang_id,
        qty_permintaan: this.is_data.qty_permintaan,
        qty_barang: this.is_data.qty_permintaan,
        indikasi_racikan: this.is_data.indikasi_racikan,
        keterangan_racikan: this.is_data.keterangan_racikan
      };
      this.items.push(x);
      this.is_data.arr_barang = [];
      this.cek_komposisi = null;
      this.is_data.qty_permintaan = 0;
      this.is_data.qty_barang = 0;
      this.is_data.indikasi_racikan = ""
      this.is_data.keterangan_racikan = ""
    },
    inikah() {
      this.$v.$reset();
      this.is_data = {
        no_racikan: "",
        qty_permintaan: "",
        qty_barang: "",
        signa_racikan: "",
        aturan_pakai_racikan: "",
        keterangan_racikan: "",
        arr_barang: [],
      };
      this.cek_komposisi = null;
      this.items = [];
    },
    formCheck() {
      // console.log(Object.keys(this.is_data.arr_barang).length == true);
      // console.log(this.is_data.qty_permintaan == true);
      if (Object.keys(this.is_data.arr_barang).length && this.is_data.qty_permintaan) {
        // console.log(true);
        return false;
      } else {
        // console.log(false);
        return true;
      }
    },
    namaDanStock ({ nama_barang, qty_stock }) {
      return `${nama_barang} — [${qty_stock}]`
    },
    hapusDataRacikan(val) {
      // console.log(val);
      this.items.splice(val.index, 1)
    }, 
    async cekAlergi(ev) {
      const vm = this
      // console.log("alergi", ev);
      // console.log(this.dataResep);
      let x = {
        rm_id: vm.dataResep.rm_id,
        ms_barang_id: ev.ms_barang_id
      }
      try {
        let res = await vm.$axios.post('/resep/cek_alergi', x)
        if (res.data.status == 200) {
          if (res.data.data) {
            vm.$emit("alertFromChild", {
              variant: "warning",
              msg: "PASIEN MEMILIKI ALERGI",
              showing: true,
            });
          }
        }
      } catch (err) {
       console.log(err);
       vm.$emit("alertFromChild", {
          variant: "danger",
          msg: "TERJADI GANGGUAN JARINGAN, GAGAL MEMERIKSA ALERGI",
          showing: true,
        }); 
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        no_racikan: "",
        qty_permintaan: "",
        qty_barang: "",
        signa_racikan: "",
        aturan_pakai_racikan: "",
        keterangan_racikan: "",
        arr_barang: [],
      };
      this.is_racikan = false;
      this.cek_komposisi = null;
      this.items = [];
    },
  },
};
</script>
