<template>
  <div v-if="dataRow">
    <b-modal
        id="modal-info-batch"
        size="lg"
        centered
        :title="'Info Racikan ' + pageName"
        header-bg-variant="primary"
        header-text-variant="light"
        @hidden="tutupModal"
    >
      <b-container>
        <b-row>
          <b-col cols="6">
            <div>
              <h6><strong>Nama Obat :</strong></h6>
              <p>{{ is_data.nama_obat || "-" }}</p>
            </div>
            <div>
              <h6><strong>Signa :</strong></h6>
              <p>{{ is_data.signa_racikan || "-" }}</p>
            </div>
            <div>
              <h6><strong>Aturan Pakai :</strong></h6>
              <p>{{ is_data.aturan_pakai_racikan || "-" }}</p>
            </div>
            <div>
              <h6><strong>Jumlah :</strong></h6>
              <p>{{ is_data.qty_barang || "-" }}</p>
            </div>
            <div>
              <h6><strong>Indikasi :</strong></h6>
              <p>{{ is_data.indikasi || "-" }}</p>
            </div>
            <div>
              <h6><strong>Keterangan :</strong></h6>
              <p>{{ is_data.keterangan_racikan || "-" }}</p>
            </div>
          </b-col>
          <b-col cols="6">
            <b-card
              no-body
              class="m-0 mb-2 p-2"
              v-for="(value, key) in is_data.obat_pasien"
              :key="key"
            >
              <b-row class="m-0 p-0">
                <b-col cols="4">
                  <h6 class="m-0 p-0">Kode Batch</h6>
                </b-col>
                <b-col cols="8"> : {{ value.kode_batch }} </b-col>
                <b-col cols="4">
                  <h6 class="m-0 p-0">Jumlah</h6>
                </b-col>
                <b-col cols="8"> : {{ value.qty_barang }} </b-col>
                <b-col cols="4">
                  <h6 class="m-0 p-0">Kadaluarsa</h6>
                </b-col>
                <b-col cols="8">
                  : {{ $moment(value.tanggal_kadaluarsa).format("LL") }}
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "modalInfoBatch",
  props: ["fileName", "dataRow"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.is_data.$invalid;
    },
    isDirty() {
      return this.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    dataRow(newVal) {
      this.is_data = newVal;
    },
  },

  // mixins: [validationMixin],
  // validations: {
  //   is_data: {
  //     username: { required },
  //     role: { required },
  //     nama: { required },
  //     alamat: { required },
  //     puskesmas_id: { required },
  //   },
  // },
  methods: {
    // checkIfValid(fieldName) {
    //   const field = this.is_data[fieldName];
    //   if (!field.$dirty) {
    //     return null;
    //   }
    //   return !(field.$invalid || field === "");
    // },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      let data_edited = {
        id: vm.is_data.racikan_id,
        qty_barang: vm.is_data.qty_barang,
        signa_racikan: vm.is_data.signa_racikan,
        aturan_pakai_racikan: vm.is_data.aturan_pakai_racikan,
        indikasi: vm.is_data.indikasi,
        keterangan_racikan: vm.is_data.keterangan_racikan,
      }
      vm.$axios
        .post(`/${vm.file_name}/update`, data_edited)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            // vm.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-info-batch");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      // this.$reset();
      this.is_data = null;
    },
  },
};
</script>

<style></style>
