<template>
  <div id="table-detail-resep">
    <b-overlay :show="showOverlay" rounded="sm">
      <b-container fluid>
        <!-- <pre>{{ itemPrint }}</pre> -->
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Buat Baru Obat Pasien</strong>
                </h5>
              </template>

              <b-row :align-h="!startResep ? 'between' : 'end'">
                <b-col v-if="!startResep" cols="6" md="6" lg="6">
                  <b-button variant="success" @click="mulaiResep()">
                    <!-- <CIcon name="cil-plus" />  -->
                    Mulai Resep
                  </b-button>
                </b-col>
                <b-col cols="auto" offset="auto">
                  <b-button
                    :disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    class="mr-1"
                    variant="info"
                    v-c-tooltip.hover.click="'Obat telah diserahkan'"
                    @click="selesaiResep(3)"
                  >
                    <!-- <CIcon name="cil-plus" />  -->
                    Selesaikan
                  </b-button>
                  <b-button
                    :disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    class="mr-1"
                    variant="warning"
                    v-c-tooltip.hover.click="'Obat tidak diambil'"
                    @click="selesaiResep(0)"
                  >
                    <!-- <CIcon name="cil-plus" />  -->
                    Tidak Ambil Obat
                  </b-button>
                  <b-button
                    class="mr-1"
                    variant="primary"
                    v-c-tooltip.hover.click="'Lihat semua resep'"
                    @click="$router.push('/apotek/resep')"
                  >
                    <!-- <CIcon name="cil-plus" />  -->
                    List Resep
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <hr />
                </b-col>
              </b-row>

              <b-alert dismissible fade :show="showing" :variant="variant">
                {{ msg }}
              </b-alert>

              <!-- =========================== START DETAIL RESEP =========================== -->
              <b-row>
                <b-col>
                  <b-card>
                    <b-row>
                      <b-col cols="6">
                        <b-container>
                          <b-row>
                            <b-col cols="4" style="display: flex; align-self: center">
                              No. Pendaftaran
                            </b-col>
                            <b-col cols="1" style="display: flex; align-self: center">
                              :
                            </b-col>
                            <b-col cols="7">
                              <b-form-input
                                plaintext
                                size="sm"
                                v-model="dataResep.no_pendaftaran"
                              ></b-form-input>
                            </b-col>
                            <b-col cols="4">Nomor Antrian</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.no_antrian || "-" }} </b-col>
                            <b-col cols="4">Tanggal Kunjungan</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.tanggal_daftar
                                  ? this.$moment(dataResep.tanggal_daftar).format(
                                      "DD MMMM YYYY, hh:mm:ss"
                                    )
                                  : "-"
                              }}
                            </b-col>
                            <b-col cols="4">No. eRM</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.no_rm || "-" }} </b-col>
                            <b-col cols="4">NIK</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.nik || "-" }} </b-col>
                            <b-col cols="4">Nama</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.nama_lengkap || "-" }} </b-col>
                            <b-col cols="4">Jenis Kelamin</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              <!-- prettier-ignore-start -->
                              {{
                                dataResep.jenis_kelamin == "L"
                                  ? "Laki-laki"
                                  : dataResep.jenis_kelamin == "P"
                                  ? "Perempuan"
                                  : "-"
                              }}
                              <!-- prettier-ignore-end -->
                            </b-col>
                            <b-col cols="4">Tempat & Tgl Lahir</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{ dataResep.tempat_lahir || "-" }},
                              {{
                                this.$moment(dataResep.tanggal_lahir).format(
                                  "DD-MM-YYYY"
                                ) || "-"
                              }}
                            </b-col>
                            <b-col cols="4">Umur</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.usia_pasien || "-" }} </b-col>
                            <b-col cols="4">Berat Badan</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.berat_badan || "-" }} </b-col>
                            <b-col cols="4">Tinggi Badan</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.tinggi_badan || "-" }} </b-col>
                            <b-col cols="4">Asuransi</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{ dataResep.asuransi_pasien || "UMUM" }}
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-col>
                      <b-col cols="6">
                        <b-container class="mb-2">
                          <b-row>
                            <b-col cols="4" style="display: flex; align-self: center">
                              No. Resep
                            </b-col>
                            <b-col cols="1" style="display: flex; align-self: center">
                              :
                            </b-col>
                            <b-col cols="7">
                              <b-form-input
                                plaintext
                                v-model="dataResep.no_resep"
                                size="sm"
                              ></b-form-input>
                            </b-col>

                            <b-col cols="4">Tanggal Resep</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{ $moment(dataResep.tanggal_daftar).format("DD MMMM YYYY") || "-" }}
                            </b-col>

                            <b-col cols="4" style="display: flex; align-self: center">
                              Ruangan
                            </b-col>
                            <b-col cols="1" style="display: flex; align-self: center">
                              :
                            </b-col>
                            <b-col cols="7">
                              <b-form-select
                                id="dokter_id"
                                disabled
                                v-model="dataResep.ms_poli_id"
                                :options="option_poli"
                                placeholder="-- Pilih Poli --"
                                size="sm"
                              />
                              <!-- <Multiselect
                              v-model="dataResep.ms_poli_id"
                              :options="option_poli"
                              size="sm"
                              :multiple="false"
                              :searchable="true"
                              :close-on-select="true"
                              :show-labels="false"
                              label="nama_poli"
                              track-by="ms_poli_id"
                            ></Multiselect> -->
                            </b-col>

                            <b-col cols="4">Dokter</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.nama_dokter || "-" }} </b-col>

                            <b-col cols="4">Perawat</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7"> {{ dataResep.nama_perawat || "-" }} </b-col>

                            <b-col cols="4" style="display: flex; align-self: center">
                              Apoteker <span class="text-danger"> * </span>
                            </b-col>
                            <b-col cols="1" style="display: flex; align-self: center">
                              :
                            </b-col>
                            <b-col cols="7">
                              <Multiselect
                                id="apoteker"
                                :disabled="startResep"
                                v-model="dataResep.obj_apoteker"
                                :options="filteredOptions(option_tenaga_medis)"
                                placeholder="-- Pilih Apoteker --"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                track-by="tenaga_medis_id"
                                label="nama_tenaga_medis"
                                size="sm"
                              />
                            </b-col>

                            <b-col cols="4">STR Apoteker</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.obj_apoteker
                                  ? dataResep.obj_apoteker.kj_str_number
                                  : "-"
                              }}
                            </b-col>

                            <b-col cols="4" style="display: flex; align-self: center">
                              Asisten Apoteker
                            </b-col>
                            <b-col cols="1" style="display: flex; align-self: center">
                              :
                            </b-col>
                            <b-col cols="7">
                              <Multiselect
                                id="asisten_apoteker"
                                :disabled="startResep"
                                v-model="dataResep.obj_asisten_apoteker"
                                :options="filteredOptions(option_tenaga_medis)"
                                placeholder="-- Pilih Asisten Apoteker --"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                track-by="tenaga_medis_id"
                                label="nama_tenaga_medis"
                                size="sm"
                              />
                            </b-col>

                            <!-- <b-col cols="12">
                              {{ dataResep.obj_asisten_apoteker || "[]" }}
                            </b-col> -->

                            <b-col cols="4">Alergi Obat</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7" v-if="dataResep.data_alergi">
                              <ul class="pl-3" v-if="dataResep.data_alergi.length">
                                <li>
                                  Obat :
                                  <ul class="pl-3">
                                    <li
                                      v-for="(item, key) in dataResep.data_alergi"
                                      :key="key"
                                    >
                                      {{ item.nama_alergi }}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <div v-else>-</div>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6" offset="6">
                        <b-container>
                          <b-row>
                            <b-col cols="4">Resep dibuat</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.tanggal_mulai_dibuat
                                  ? $moment(dataResep.tanggal_mulai_dibuat).format("LLL")
                                  : "-"
                              }}
                            </b-col>
                            <b-col cols="4">Oleh</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.tanggal_mulai_dibuat
                                  ? $store.state.nama_user
                                  : "-"
                              }}
                            </b-col>

                            <b-col cols="4">Resep diubah</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.tanggal_selesai_dibuat
                                  ? $moment(dataResep.tanggal_selesai_dibuat).format("LLL")
                                  : "-"
                              }}
                            </b-col>
                            <b-col cols="4">Oleh</b-col>
                            <b-col cols="1"> : </b-col>
                            <b-col cols="7">
                              {{
                                dataResep.tanggal_selesai_dibuat
                                  ? $store.state.nama_user
                                  : "-"
                              }}
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12" lg="12">
                        <hr />
                      </b-col>
                    </b-row>
      
      
                    <!-- =========================== START DIAGNOSA =========================== -->
                    <b-row>
                      <b-col cols="12" md="12" lg="12" xl="12">
                        <b-button v-b-toggle.collapse-1 variant="light" block style="background-color: #D52F65;color: #fff;border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
                          <div style="display: flex;justify-content: space-between;">
                            <span>Riwayat Diagnosa</span>
                            <span><CIcon name="cil-caret-bottom" /></span>
                          </div>
                          
                        </b-button>
                        <b-collapse id="collapse-1">
                          <b-card style="border: 1px solid #D3D3D3">
                            <b-row>
                              <b-col cols="12" sm="12" md="12" lg="12">
                                <b-table
                                  :items="itemDiagnosa"
                                  :fields="fieldDiagnosa"
                                  :current-page="currentPageDiagnosa"
                                  :per-page="perPageDiagnosa"
                                  :filter="filterDiagnosa"
                                  :filter-included-fields="filterOnDiagnosa"
                                  responsive
                                  show-empty
                                  small
                                  @filtered="onFilteredDiagnosa"
                                  bordered
                                  striped
                                  hover
                                  :busy="tableBusyDiagnosa"
                                  table-class="table-color"
                                >
                                  <template #cell(no)="item">
                                    {{ item.index + 1 }}
                                  </template>
                                  <template #cell(diagnosa)="item">
                                    {{ item.item.nama_diagnosa }} - [{{
                                      item.item.nm_diag.toUpperCase()
                                    }}] - ({{ item.item.kd_diag }})
                                  </template>
                                  <template #cell(actions)="item">
                                    <b-button
                                      variant="warning"
                                      class="mr-1"
                                      v-c-tooltip.hover.click="'Edit Data'"
                                      v-b-modal.modal-edit
                                      @click="is_data = item.item"
                                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                                    >

                                    <b-button
                                      variant="danger"
                                      class="mr-1"
                                      v-c-tooltip.hover.click="'Hapus Data'"
                                      v-b-modal.modal-delete
                                      @click="is_data = item.item"
                                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                                    >
                                  </template>
                                </b-table>

                              </b-col>
                            </b-row>
                          </b-card>
                        </b-collapse>
                      </b-col>
                    </b-row>
                    <b-row class="my-2">
                      <b-col cols="12" md="12" lg="12" xl="12">
                        <b-button v-b-toggle.collapse-2 variant="light" block style="background-color: #D52F65;color: #fff;border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
                          <div style="display: flex;justify-content: space-between;">
                            <span>Rekonsiliasi Obat</span>
                            <span><CIcon name="cil-caret-bottom" /></span>
                          </div>
                          
                        </b-button>
                        <b-collapse id="collapse-2">
                          <b-card style="border: 1px solid #D3D3D3">
                            <b-row>
                              <b-col cols="12" sm="12" md="12" lg="12">
                                <b-table
                                  :items="itemRekonsiliasi"
                                  :fields="fieldRekonsiliasi"
                                  :current-page="currentPageRekonsiliasi"
                                  :per-page="perPageRekonsiliasi"
                                  :filter="filterRekonsiliasi"
                                  :filter-included-fields="filterOnRekonsiliasi"
                                  responsive
                                  show-empty
                                  small
                                  @filtered="onFilteredRekonsiliasi"
                                  bordered
                                  striped
                                  hover
                                  :busy="tableBusyRekonsiliasi"
                                  table-class="table-color"
                                >
                                  <template #cell(no)="item">
                                    {{ item.index + 1 }}
                                  </template>
                                  <template #cell(actions)="item">
                                    <b-button
                                      variant="warning"
                                      class="mr-1"
                                      v-c-tooltip.hover.click="'Edit Data'"
                                      v-b-modal.modal-edit
                                      @click="is_data = item.item"
                                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                                    >

                                    <b-button
                                      variant="danger"
                                      class="mr-1"
                                      v-c-tooltip.hover.click="'Hapus Data'"
                                      v-b-modal.modal-delete
                                      @click="is_data = item.item"
                                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                                    >
                                  </template>
                                </b-table>

                              </b-col>
                            </b-row>
                          </b-card>
                        </b-collapse>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>

              <!-- =========================== START REKONSILIASI =========================== -->
              

              <!-- =========================== START OBAT =========================== -->
              <!-- <b-row class="my-2">
                <b-col cols="12" md="12" lg="12">
                  <b-card sub-title="Obat Pasien">
                    <b-container fluid>
                      <b-row align-h="end">
                        <b-col cols="auto">
                          <b-button
                            :disabled="
                              !startResep ||
                              dataResep.status_resep == 3 ||
                              dataResep.status_resep == 0
                            "
                            class="mb-2"
                            variant="primary"
                            v-c-tooltip.hover.click="'Tambah Racikan'"
                            @click="tambahRacikan()"
                          >Tambah Resep</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                    <b-table-simple hover small responsive bordered table-class="table-color">
                      <b-thead>
                        <b-tr>
                          <b-th v-for="field in fieldObat" :key="field.key" :class="field.class">{{ field.label }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <tr v-for="(item, index) in itemHeadRacikan" :key="index">
                          <td v-if="!item.racikan_id" scope="row" class="text-center"> <strong>{{ item.no }}.</strong> </td>
                          <td v-if="!item.racikan_id">{{ item.no_racikan ? item.no_racikan : "" }}</td>
                          <td v-if="!item.racikan_id"></td> 
                          <td v-if="!item.racikan_id" class="text-center">{{ item.jumlah_bungkus ? item.jumlah_bungkus : "" }}</td>
                          <td v-if="!item.racikan_id" colspan="5"></td> 

                          <td v-if="item.racikan_id" colspan="2"></td>
                          <td v-if="item.racikan_id" style="white-space: nowrap;">{{ item.nama_obat }}</td>
                          <td v-if="item.racikan_id"></td>
                          <td v-if="item.racikan_id" class="text-center" >{{ item.qty_permintaan }}</td>
                          <td v-if="item.racikan_id">{{ item.aturan_pakai_racikan }}</td>
                          <td v-if="item.racikan_id">{{ item.signa_racikan }}</td>
                          <td v-if="item.racikan_id" class="text-center">{{ item.qty_barang }}</td>
                          <td v-if="item.racikan_id" class="text-center table-option-4">
                            <b-button
                              variant="info"
                              class="mr-1"
                              v-c-tooltip.hover.click="'View Detail'"
                              @click="openModalInfoBatch(item)"
                              ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                            >

                            <b-button
                              variant="warning"
                              class="mr-1"
                              v-c-tooltip.hover.click="'Edit Racikan'"
                              @click="getDetailRacikan(item)"
                              ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                            >
                          </td>
                        </tr>
                        <tr v-for="(data, index) in itemObat" :key="index + data.racikan_id">
                          <td scope="row" class="text-center"><strong>{{ panjangHead + index + 1 }}.</strong></td>
                          <td>{{ data.no_racikan ? data.no_racikan : "-" }}</td>
                          <td style="white-space: nowrap;">{{ data.nama_obat }}</td>
                          <td class="text-center">{{ data.jumlah_bungkus ? data.jumlah_bungkus : "" }}</td>
                          <td class="text-center">{{ data.qty_permintaan }}</td>
                          <td>{{ data.aturan_pakai_racikan }}</td>
                          <td>{{ data.signa_racikan }}</td>
                          <td class="text-center">{{ data.qty_barang }}</td>
                          <td class="text-center table-option-4">
                            <b-button
                              variant="info"
                              class="mr-1"
                              v-c-tooltip.hover.click="'View Detail'"
                              @click="openModalInfoBatch(data)"
                              ><CIcon name="cil-info" /> {{ data.actions }}</b-button
                            >

                            <b-button
                              variant="warning"
                              class="mr-1"
                              v-c-tooltip.hover.click="'Edit Racikan'"
                              @click="getDetailRacikan(data)"
                              ><CIcon name="cil-pencil" /> {{ data.actions }}</b-button
                            >
                          </td>
                        </tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card>
                </b-col>
              </b-row> -->

              <b-card>
                <b-row class="my-2">
                  <b-col cols="12">
                    <h5 class="custom-title-card"><strong>RESEP</strong></h5>
                  </b-col>
                  <!-- <b-col cols="12">
                    <div class="mt-2 d-flex justify-content-end">
                      <b-button
                        :disabled="busy"
                        @click="is_data_detail = {}, $bvModal.show('modal-kunjungan-obat-resep')"
                        variant="primary"
                      >Tambah</b-button>
                    </div>
                  </b-col> -->
                  <b-col cols="12" md="12" lg="12">
                    <b-table-simple hover small responsive bordered>
                      <b-thead class="table-color">
                        <b-tr>
                          <b-th v-for="field in fieldsObat" :key="field.key" :class="field.class">{{ field.label }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <tr v-for="(item, index) in itemsResep" :key="index">
                          <th v-if="item.type == 'head'" scope="row" class="text-center">{{ item.no }}.</th>
                          <td v-if="item.type == 'head' && item.no_racikan" colspan="6">{{ `${item.no_racikan} - ${item.jumlah_bungkus} bungkus ${item.indikasi_head_racikan ? ` - ${item.indikasi_head_racikan}` : ''}` }}</td>
                          <td v-if="item.type == 'head' && !item.no_racikan" colspan="6">Tanpa Racikan</td>
                        
                          <td v-if="item.type == 'sub'"></td>
                          <td v-if="item.type == 'sub'">{{ item.nama_barang }}</td>
                          <td v-if="item.type == 'sub'">{{ item.nama_qty_obat }}</td>
                          <td v-if="item.type == 'sub'">{{ item.nama_qty_permintaan }}</td>
                          <td v-if="item.type == 'sub'">{{ item.aturan_pakai_racikan }}</td>
                          <td v-if="item.type == 'sub'">{{ item.signa_racikan }}</td>
                          <td v-if="item.type == 'sub'">{{ item.nama_harga_sub_obat_jual }}</td>
                          
                          <td v-if="item.type == 'detail'"></td>
                          <td v-if="item.type == 'detail'" colspan="8">
                            <b-card class="m-0">
                              <b-row class="m-0 p-0" style="row-gap: 1rem;">
                                <b-col cols="6">
                                  <div>
                                    <h6><strong>Indikasi</strong></h6>
                                    <p>{{item.indikasi_obat || '-'}}</p>
                                  </div>
                                  <div>
                                    <h6><strong>Keterangan</strong></h6>
                                    <p>{{item.keterangan_obat || '-'}}</p>
                                  </div>
                                </b-col>
                                <b-col cols="6">
                                  <div class="" v-for="(value, key) in item.item_obat_jual" :key="key">
                                    <b-card class="m-0">
                                      <b-row class="m-0 p-0">
                                        <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                                        <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                                        <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                                        <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col>
                                        <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                                        <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                                      </b-row>
                                    </b-card>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-card>
                          </td>
                          <td class="text-center">
                            <div class="d-flex">
                              <b-button
                                v-if="item.type == 'head'"
                                :disabled="!startResep || selesai"
                                variant="info"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Detail Racikan'"
                                @click="is_data_detail = {...item, head_racikan_id: item.head_racikan_id || true}, $bvModal.show('modal-kunjungan-obat-resep')"
                              ><CIcon name="cil-info" /></b-button>
                              <b-button
                                v-if="item.type == 'sub'"
                                :disabled="!startResep"
                                variant="info"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Detail Sub Obat'"
                                @click="detailSub(index)"
                              ><CIcon name="cil-info" /></b-button>
                              <b-button
                                v-if="item.type == 'sub'"
                                :disabled="!startResep || selesai"
                                variant="warning"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Edit Racikan'"
                                @click="getDetailRacikan(item)"
                                ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                              >
                            </div>
                          </td>
                        </tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-card>


              <!-- =========================== START CPPT =========================== -->
              <b-row class="my-2">
                <b-col cols="12" md="12" lg="12">
                  <b-card sub-title="Catatan Perkembangan Pasien Terintegrasi (CPPT)">
                    <b-table
                      :items="itemCPPT"
                      :fields="fieldCPPT"
                      :current-page="currentPageCPPT"
                      :per-page="perPageCPPT"
                      :filter="filterCPPT"
                      :filter-included-fields="filterOnCPPT"
                      responsive
                      show-empty
                      small
                      @filtered="onFilteredCPPT"
                      bordered
                      striped
                      hover
                      :busy="tableBusyCPPT"
                      table-class="custom-table"
                    >
                      <template #cell(actions)="item">
                        <b-button
                          variant="warning"
                          class="mr-1"
                          v-c-tooltip.hover.click="'Edit Data'"
                          v-b-modal.modal-edit
                          @click="is_data = item.item"
                          ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                        >

                        <b-button
                          variant="danger"
                          class="mr-1"
                          v-c-tooltip.hover.click="'Hapus Data'"
                          v-b-modal.modal-delete
                          @click="is_data = item.item"
                          ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                        >
                      </template>
                    </b-table>
                  </b-card>
                </b-col>
              </b-row>
              <b-row align-h="end">
                <b-col cols="auto" offset="auto">
                  <b-button
                    :disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    class="mr-2 cetak-label"
                    v-c-tooltip.hover.click="'Cetak Label'"
                    @click="printLabelKemasan('label_kemasan')"
                  >
                    <!-- <CIcon name="cil-plus" />  -->
                    Cetak Label
                  </b-button>
                  <!-- <b-button
                    :disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    class="mr-1 cetak-rol-btn"
                    v-c-tooltip.hover.click="'Cetak Roll'"
                    @click="printLabelKemasan('cetak_rol')"
                  >
                    Cetak RolL
                  </b-button> -->
                  <b-button
                    :disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    class="cetak-btn"
                    v-c-tooltip.hover.click="'Cetak Resep'"
                    @click="printSederhana('cetak_resep')"
                  >
                    <!-- <CIcon name="cil-plus" />  -->
                    Cetak Resep
                  </b-button>
                  <CetakRoll
                    :nama_tombol="'Cetak Roll'"
                    :is_disabled="
                      !startResep ||
                      dataResep.status_resep == 3 ||
                      dataResep.status_resep == 0
                    "
                    :printResep="printDataRoll"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

      </b-container>
    </b-overlay>
    <modal-edit-detail-racikan
      fileName="racikan"
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-info-batch 
      :fileName="file_name"
      :data-row="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-tambah-resep 
      fileName="resep"
      :option_obat="option_obat"
      :option_komposisi="option_komposisi"
      :dataResep="is_data"
      :poolAlergi="pool_alergi"
      @alertFromChild="triggerAlert($event)"
      @addResep="getDatas()"
    />

    <!-- PRINT -->
    <div style="display: none;" id="cetak_rol">
      <section class="content-print paper-58" v-if="itemPrint">
        <div class="sheet" >
          <div class="w-99" style="border:1px solid #333;margin-bottom:2.5mm;">
            <table border="0" class="custom-table">
              <tbody>
                  <tr>
                      <td style="width: 50%;vertical-align: middle;padding:1mm;">
                          <h6 style="margin:0;padding:0;font-size: 8pt;">{{ dataResep.nama_puskesmas }}</h6>
                          <h6 style="margin:0;padding:0;font-size: 8pt;">{{ dataResep.nama_lengkap }}</h6>
                      </td>
                      
                      <td style="width: 50%;vertical-align: middle;padding:1mm;">
                          <h6 style="text-align: right;margin:0;padding:0;font-size: 8pt;">{{ $moment(new Date()).format("DD-MM-YYYY") }}</h6>
                          <h6 style="text-align: right;margin:0;padding:0;font-size: 8pt;">{{ dataResep.usia_tahun }} Thn {{ dataResep.usia_bulan }} Bln {{ dataResep.usia_hari }} Hari</h6>
                      </td>
                  </tr>
              </tbody>
            </table>
            <div class="line" style="margin-top:1.5mm"></div>
            <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="vertical-align: middle;padding-top: 1.5mm;padding-bottom: 1.5mm;">
                            <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ data_print.nama_obat }}</strong></h6>
                            <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ data_print.signa_racikan }}</strong></h6>
                            <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ data_print.aturan_pakai_racikan }}</strong></h6>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="line" style="margin-bottom:1.5mm"></div>
            <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 17mm;padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">Jumlah Obat</h6></td>
                        <td style="width: 2.5mm;text-align: center;border-left: hidden;border-right: hidden;padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">:</h6></td>
                        <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">{{ data_print.qty_barang }}</h6></td>
                    </tr>

                    <tr>
                        <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">Exp. Date</h6></td>
                        <td style="text-align: center;border-left: hidden;border-right: hidden;padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">:</h6></td>
                        <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">{{ $moment(data_print.obat_pasien[0].tanggal_kadaluarsa).format("DD-MM-YYYY") || '-' }}</h6></td>
                    </tr>

                    <tr>
                        <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">Indikasi</h6> </td>
                        <td style="text-align: center;border-left: hidden;border-right: hidden;padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">:</h6></td>
                        <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">{{ data_print.indikasi }}</h6></td>
                    </tr> 
                </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <!-- PRINT LABEL KEMASAN OBAT -->
    <div style="position: relative; display: none;" id="label_kemasan">
      <section class="content-print paper-58" v-if="itemPrint" >
        <div class="sheet">
          <div class="w-99" style="border:1px solid #333;margin-bottom:2.5mm;" v-for="(obat, index) in itemPrint" :key="index">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 50%;vertical-align: middle;padding:1mm;">
                    <h6 style="margin:0;padding:0;font-size: 8pt;">{{ $store.state.nama_puskesmas || "-" }}</h6>
                  </td>
                  
                  <td style="width: 50%;vertical-align: middle;padding:1mm;">
                    <h6 style="text-align: right;margin:0;padding:0;font-size: 8pt;">{{ $moment(dataResep.tanggal_daftar).format("DD MMMM YYYY") }}</h6>
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%;vertical-align: middle;">
                    <h6 style="margin:0;padding:0;font-size: 8pt;">{{ dataResep.nama_lengkap }}</h6>
                  </td>
                  
                  <td style="width: 50%;vertical-align: middle;">
                    <h6 style="text-align: right;margin:0;padding:0;font-size: 8pt;">{{ dataResep.usia_pasien }}</h6>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="line"></div>
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="vertical-align: middle;padding-top: 1.5mm;padding-bottom: 1.5mm;">
                    <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ obat.nama_obat }}</strong></h6>
                    <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ obat.signa_racikan }}</strong></h6>
                    <h6 style="text-align: center;font-size: 8pt;margin:0;padding:0;"><strong>{{ obat.aturan_pakai_racikan }}</strong></h6>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="line"></div>
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 17mm;padding: 1mm;vertical-align: top;"><h6 style="font-size:8pt;margin:0;padding:0">Jumlah Obat</h6></td>
                  <td style="width: 2.5mm;text-align: center;border-left: hidden;border-right: hidden;padding: 1mm;"><h6 style="font-size:8pt;margin:0;padding:0">:</h6></td>
                  <td style="padding:1mm;"><h6 style="font-size:8pt;margin:0;padding:0;">{{ obat.qty_barang }}</h6></td>
                </tr>

                <tr v-if="obat.obat_pasien.length">
                  <td style="padding: 1mm;vertical-align: top;"><h6 style="font-size:8pt;margin:0;padding:0">Exp. Date</h6></td>
                  <td style="text-align: center;border-left: hidden;border-right: hidden;padding: 1mm;"><h6 style="font-size:8pt;margin:0;padding:0">:</h6></td>
                  <td style="padding: 1mm;"><h6 style="font-size:8pt;margin:0;padding:0">{{ $moment(obat.obat_pasien[0].tanggal_kadaluarsa).format("DD MMMM YYYY") }}</h6></td>
                </tr>

                <tr>
                  <td style="padding: 1mm;vertical-align: top;"><h6 style="font-size:8pt;margin:0;padding:0">Indikasi</h6> </td>
                  <td style="text-align: center;border-left: hidden;border-right: hidden;padding: 1mm;"><h6 style="font-size:8pt;margin:0;padding:0">:</h6></td>
                  <td style="padding: 1mm;"><h6 style="font-size:8pt;margin:0;padding:0">{{ obat.indikasi }}</h6></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <!-- CETAK RESEP -->
    <div style="position: relative; display: none" id="cetak_resep">
      <section class="content-print A4">
        <div class="sheet" >
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;border-bottom: 1px solid #333;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 70%;vertical-align: middle;border-bottom: 1px solid #333;">
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }}</h6>
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;">PUSKESMAS {{ dataResep.nama_puskesmas }} </h6>
                      <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 6px;">{{ dataResep.alamat_puskesmas }}</h6>
                      <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                  </td>
                  <td style="width: 15%;vertical-align: middle;border-bottom: 1px solid #333;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <table border="0" class="custom-table">
              <tbody>
                <!-- <tr>
                  <td style="width: 75mm;">ID Resep</td>
                  <td class="autowidth">:</td>
                  <td><span>-</span></td>
                </tr>

                <tr>
                  <td>No. Antrean</td>
                  <td>:</td>
                  <td><span>-</span></td>
                </tr> -->

                <tr>
                  <td style="width: 75mm;">No. Resep</td>
                  <td class="autowidth">:</td>
                  <td>{{ dataResep.no_resep }}</td>
                </tr>

                <tr>
                  <td>Tanggal Resep</td>
                  <td>:</td>
                  <td>{{ $moment(dataResep.tanggal_daftar).format("DD MMMM YYYY") }}</td>
                </tr>

                <tr>
                  <td>No. Pendaftaran</td>
                  <td>:</td>
                  <td>{{ dataResep.no_pendaftaran }}</td>
                </tr>

                <tr>
                  <td>Poli/Ruangan</td>
                  <td>:</td>
                  <td>{{ dataResep.nama_poli }}</td>
                </tr>


                <tr>
                  <td>Dokter / Tenaga Medis</td>
                  <td>:</td>
                  <td>{{ dataResep.nama_dokter }}</td>
                </tr>

                <tr>
                  <td>Perawat / Bidan / Nutrisionist / Sanitarian </td>
                  <td>:</td>
                  <td>{{ dataResep.nama_perawat }}</td>
                </tr>

                <tr>
                  <td>No. eRM</td>
                  <td>:</td>
                  <td>{{ dataResep.no_rm }}</td>
                </tr>

                <tr>
                  <td>NIK</td>
                  <td>:</td>
                  <td>{{ dataResep.nik }}</td>
                </tr>

                <tr>
                  <td>Nama Pasien</td>
                  <td>:</td>
                  <td>{{ dataResep.nama_lengkap }}</td>
                </tr>

                <tr>
                  <td>Jenis Kelamin</td>
                  <td>:</td>
                  <td>{{ dataResep.jenis_kelamin == "P" ? "Perempuan" : "Laki-laki" }}</td>
                </tr>

                <tr>
                  <td>Tempat/Tanggal Lahir</td>
                  <td>:</td>
                  <td>{{ dataResep.tempat_lahir }} / {{ $moment(dataResep.tanggal_lahir).format("DD MMMM YYYY") }}</td>
                </tr>

                <tr>
                  <td>Umur</td>
                  <td>:</td>
                  <td>{{ dataResep.usia_pasien }}</td>
                </tr>

                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{{ dataResep.alamat_sekarang }}</td>
                </tr>

                <tr>
                  <td>Alergi</td>
                  <td>:</td>
                  <td v-if="dataResep.data_alergi">
                    <!-- {{ pool_alergi }} -->
                    <ul style="padding-left: 15px;" v-if="dataResep.data_alergi.length">
                      <li v-for="val in dataResep.data_alergi" :key="val.ms_alergi_id">{{ val.nama_alergi }}</li>
                    </ul>

                    <div v-else> - </div>
                  </td>
                  <td v-else> - </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <table border="1" class="custom-table custom-border-table">
              <thead>
                <tr>
                  <th colspan="8" style="font-size:10pt !important">RESEP</th>
                </tr>
                <tr>
                  <th style="font-size:10pt !important">No.</th>
                  <th style="font-size:10pt !important">Racikan</th>
                  <th style="font-size:10pt !important">Nama Obat</th>
                  <th style="font-size:10pt !important">Jumlah<br/>Bungkus</th>
                  <th style="font-size:10pt !important">Permintaan</th>
                  <th style="font-size:10pt !important">Aturan<br/>Pakai</th>
                  <th style="font-size:10pt !important">Signa</th>
                  <th style="font-size:10pt !important">Keterangan</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in itemHeadRacikan" :key="index">
                  <td style="font-size:10pt !important" v-if="!item.racikan_id" scope="row" class="text-center"> <strong>{{ item.no }}.</strong> </td>
                  <td style="font-size:10pt !important" v-if="!item.racikan_id">{{ item.no_racikan ? item.no_racikan : "" }}</td>
                  <td style="font-size:10pt !important" v-if="!item.racikan_id"></td> 
                  <td style="font-size:10pt !important" v-if="!item.racikan_id" class="text-center">{{ item.jumlah_bungkus ? item.jumlah_bungkus : "" }}</td>
                  <td style="font-size:10pt !important" v-if="!item.racikan_id" colspan="4"></td> 

                  <td style="font-size:10pt !important" v-if="item.racikan_id" colspan="2"></td>
                  <!-- <td v-if="item.racikan_id"></td> -->
                  <td v-if="item.racikan_id" style="white-space: nowrap;font-size:10pt !important">{{ item.nama_obat }}</td>
                  <td style="font-size:10pt !important" v-if="item.racikan_id"></td>
                  <td style="font-size:10pt !important" v-if="item.racikan_id" class="text-center" >{{ item.qty_permintaan }}</td>
                  <td style="font-size:10pt !important" v-if="item.racikan_id">{{ item.aturan_pakai_racikan }}</td>
                  <td style="font-size:10pt !important" v-if="item.racikan_id">{{ item.signa_racikan }}</td>
                  <td style="font-size:10pt !important" v-if="item.racikan_id" class="text-center">{{ item.qty_barang }}</td>
                </tr>
                <tr v-for="(data, index) in itemObat" :key="index + data.racikan_id">
                  <td style="font-size:10pt !important" scope="row" class="text-center"><strong>{{ panjangHead + index + 1 }}.</strong></td>
                  <td style="font-size:10pt !important">{{ data.no_racikan ? data.no_racikan : "-" }}</td>
                  <td style="white-space: nowrap;font-size:10pt !important">{{ data.nama_obat }}</td>
                  <td style="font-size:10pt !important" class="text-center">{{ data.jumlah_bungkus ? data.jumlah_bungkus : "" }}</td>
                  <td style="font-size:10pt !important" class="text-center">{{ data.qty_permintaan }}</td>
                  <td style="font-size:10pt !important">{{ data.aturan_pakai_racikan }}</td>
                  <td style="font-size:10pt !important">{{ data.signa_racikan }}</td>
                  <td  style="font-size:10pt !important" class="text-center">{{ data.qty_barang }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <h6>SEMOGA LEKAS SEMBUH</h6>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 50%;">
                    &nbsp;
                  </td>
                  <td style="width: 50%;">
                    <h6 style="text-align: center;font-size: 12pt;"><span>{{ $store.state.puskesmas.nama_kota }} / {{ $moment(dataResep.tanggal_daftar).format("DD MMMM YYYY") }}</span></h6>
                    <h6 style="text-align: center;font-size: 12pt;">Diketahui</h6>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h6 style="text-align: center;font-size: 12pt;"><span>(......................................)</span></h6>
                    <h6 style="text-align: center;font-size: 12pt;"><span>{{ dataResep.nama_dokter }}</span></h6>
                    <!-- <h6 style="text-align: center;"><span>SIP : </span></h6> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    
    <ModalResep
      :kunjungan="kunjungan"
      :is_data_detail="is_data_detail"
      :dataResep="dataResep"
      :dataSubObat="is_data"
      :listBarang="listBarang"
      :listKomposisi="listKomposisi"
      :listAlergiPasien="listAlergiPasien"
      :itemsResep="itemsResep"
      :itemsRekonsiliasi="itemRekonsiliasi"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDeleteSub
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalEditDetailRacikan from "./modalEditDetailRacikan.vue";
import ModalTambahResep from "./modalTambahResep.vue";
import ModalInfoBatch from "./modalInfoBatch.vue";
import ModalResep from "./modal_resep.vue";
import ModalDeleteSub from "./modal_delete_sub.vue";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import CetakRoll from "./cetak_roll.vue"

export default {
  name: "resep",
  components: {
    ModalResep,
    ModalDeleteSub,
    ModalDelete,
    ModalEditDetailRacikan,
    ModalTambahResep,
    ModalInfoBatch,
    Multiselect,
    CetakRoll
  },
  data() {
    return {
      startResep: true,
      file_name: "resep",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      data_print: {
        nama_obat: "",
        signa_racikan: "",
        aturan_pakai_racikan: "",
        qty_barang: "",
        obat_pasien: [{tanggal_kadaluarsa: ""}],
        indikasi: "",
      },
      dataResep: {
        obj_poli: { ms_poli_id: "" },
        obj_apoteker: {
          tenaga_medis_id: "",
        },
        obj_asisten_apoteker: {
          tenaga_medis_id: "",
        },
      },
      showOverlay: false,
      selesai: false,
      busy: false,
      is_data_detail: {},
      kunjungan: {},
      dataForm: {},
      listBarang: [],
      listKomposisi: [],
      listAlergiPasien: [],
      itemsResep: [],
      fieldsObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_obat",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_obat",
          label: "Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_obat",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_harga_sub_obat_jual",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      // ! ===================================== DIAGNOSA ===================================== \\
      fieldDiagnosa: [
        {
          key: "createdAt",
          label: "Tanggal",
          sortDirection: "desc",
          sortable: true,
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD-MM-YYYY") : "-";
          },
        },
        // {
        //   key: "nama_dokter",
        //   label: "Dokter / Tenaga Medis",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left col-3",
        //   formatter: (val) => {
        //     return val ? val : "-";
        //   },
        // },
        // {
        //   key: "nama_perawat",
        //   label: "Perawat / Tenaga Medis",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left col-3",
        //   formatter: (val) => {
        //     return val ? val : "-";
        //   },
        // },
        {
          key: "kd_diag", // icd_x
          label: "ICD-X",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-";
          },
        },
        {
          key: "diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      itemDiagnosa: [],
      totalRowsRDiagnosa: 1,
      currentPageDiagnosa: 1,
      perPageDiagnosa: 10,
      pageOptionsDiagnosa: [10, 25, 50, 100],
      filterDiagnosa: null,
      filterOnDiagnosa: [],
      tableBusyDiagnosa: false,

      // ! =================================== REKONSILIASI =================================== \\
      fieldRekonsiliasi: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center col-1",
        },
        {
          key: "nama_obat",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_rekonsiliasi",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "frekuensi",
          label: "Frekuensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "cara_pemberian",
          label: "Cara Pemberian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "status_pemberian_obat",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "perubahan_aturan_pakai",
          label: "Perubahan Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-2 text-center",
        // },
      ],
      itemRekonsiliasi: [],
      totalRowsRekonsiliasi: 1,
      currentPageRekonsiliasi: 1,
      perPageRekonsiliasi: 10,
      pageOptionsRekonsiliasi: [10, 25, 50, 100],
      filterRekonsiliasi: null,
      filterOnRekonsiliasi: [],
      tableBusyRekonsiliasi: false,

      // ! ===================================== OBAT ===================================== \\
      fieldObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "no_racikan",
          label: "Racikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-";
          },
        },
        {
          key: "nama_obat",
          label: "Nama Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jumlah_bungkus",
          label: "Jumlah Racikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_permintaan",
          label: "Permintaan Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_racikan",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_racikan",
          label: "Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_barang",
          label: "Realisasi Farmasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Aksi",
          class: "table-option-4 text-center",
        },
      ],
      itemObat: [],
      itemHeadRacikan: [],
      itemPrint: [],
      panjangHead: 0,
      pool_alergi: [],
      option_obat: [],
      option_komposisi: [],

      totalRowsRObat: 1,
      currentPageObat: 1,
      perPageObat: 10,
      pageOptionsObat: [10, 25, 50, 100],
      filterObat: null,
      filterOnObat: [],
      tableBusyObat: false,

      // ! ===================================== SOAP ===================================== \\
      fieldCPPT: [
        {
          key: "subjective",
          label: "Subjective (S)",
          class: "text-left",
        },
        {
          key: "objective",
          label: "Objective (P)",
          class: "text-left",
        },
        {
          key: "assessment",
          label: "Assessment (A)",
          class: "text-left",
        },
        {
          key: "plan",
          label: "Plan (P)",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-4 text-center",
        // },
      ],
      itemCPPT: [],
      listHead: [],
      totalRowsRCPPT: 1,
      currentPageCPPT: 1,
      perPageCPPT: 10,
      pageOptionsCPPT: [10, 25, 50, 100],
      filterCPPT: null,
      filterOnCPPT: [],
      tableBusyCPPT: false,

      option_poli: [],
      option_tenaga_medis: [],
      option_role: [
        { value: "admin", text: "admin" },
        { value: "gudang", text: "gudang" },
        { value: "kasir", text: "kasir" },
        { value: "dokter", text: "dokter" },
        { value: "bidan", text: "bidan" },
        { value: "perawat", text: "perawat" },
        { value: "sanitarian", text: "sanitarian" },
        { value: "nutrisionis", text: "nutrisionis" },
      ],

      fieldDataRacikan: [
        {
          key: "nama_obat",
          label: "Nama Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_permintaan",
          label: "Permintaan Resep",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_racikan",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_racikan",
          label: "Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_barang",
          label: "Realisasi Farmasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Aksi",
          class: "table-option-4 text-center",
        },
      ],
      printDataRoll: {},
      penampungPrint: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    showDetails() {
      return true
    },
    logo() {
      return this.$store.state.logo;
    },
  },
  mounted() {
  //   // Set the initial number of items
  //   this.totalRowsDiagnosa = this.itemDiagnosa.length;
  //   this.totalRowsRekonsiliasi = this.itemRekonsiliasi.length;
  //   this.totalRowsRObat = this.itemObat.length;
  //   this.totalRowsRCPPT = this.itemCPPT.length;
  //   this.getDatas();
  },
  activated() {
    this.totalRowsDiagnosa = this.itemDiagnosa.length;
    this.totalRowsRekonsiliasi = this.itemRekonsiliasi.length;
    this.totalRowsRObat = this.itemObat.length;
    this.totalRowsRCPPT = this.itemCPPT.length;
    this.getDatas();
  },
  methods: {
    onFilteredDiagnosa(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDiagnosa = filteredItems.length;
      this.currentPageDiagnosa = 1;
    },
    onFilteredRekonsiliasi(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsRekonsiliasi = filteredItems.length;
      this.currentPageRekonsiliasi = 1;
    },
    onFilteredObat(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsObat = filteredItems.length;
      this.currentPageObat = 1;
    },
    onFilteredCPPT(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCPPT = filteredItems.length;
      this.currentPageCPPT = 1;
    },
    detailSub(idx){
      const vm = this
      if(!vm.itemsResep[idx+1]) vm.itemsResep[idx+1] = {}
      vm.itemsResep[idx+1].type == 'detail' ? vm.itemsResep.splice(idx+1, 1) : vm.itemsResep.splice(idx+1, 0, {...vm.itemsResep[idx], type: 'detail'})
    },
    async getDatas() {
      const vm = this;
      vm.showOverlay = true;
      // vm.tableBusy = true;
      // console.log("route", this.$route.params.id);
      // let req = {
      //   kunjungan_id: this.$route.params.id,
      // };
      vm.listHead = []
      vm.itemCPPT.splice(0)
      vm.itemDiagnosa.splice(0)
      vm.itemObat.splice(0)
      vm.itemHeadRacikan.splice(0)
      vm.itemRekonsiliasi.splice(0)
      try {
        let res = await vm.$axios(`/${vm.file_name}/details_by_id/` + this.$route.params.id);
        // let res = await vm.$axios.post(`/${vm.file_name}/list`, req);
        if (res.data.status == 200 && res.data.data.length >= 1) {
          vm.dataResep = res.data.data[0];
          vm.dataResep.usia_pasien = vm.checkUsia(vm.dataResep.tanggal_lahir),
          vm.itemDiagnosa = vm.dataResep.data_diagnosa || [];
          // vm.startResep = vm.dataResep.status_resep == null ? false : true;
          vm.startResep = vm.dataResep.status_resep == 1 ? false : true;
          vm.selesai = vm.dataResep.status_resep == 3 ? true : false;
          let objAwal = vm.dataResep.data_racikan || [];

          let penampungHead = {}
          vm.panjangHead = 0
          for (let i = 0; i < objAwal.length; i++) {
            const el1 = objAwal[i];
            if (el1.head_racikan_id) {
              vm.panjangHead++
              penampungHead.no = i + 1
              penampungHead.head_racikan_id = el1.head_racikan_id
              penampungHead.no_racikan = el1.no_racikan
              penampungHead.indikasi_head_racikan = el1.indikasi_head_racikan
              penampungHead.jumlah_bungkus = el1.jumlah_bungkus
              vm.itemHeadRacikan.push(penampungHead)
              penampungHead = {}

              vm.penampungPrint.push(el1)
              for (let j = 0; j < el1.racikan.length; j++) {
                  const el2 = el1.racikan[j];
                  vm.itemHeadRacikan.splice(vm.itemHeadRacikan.map(el => el.head_racikan_id).indexOf(el2.head_racikan_id) + 1, 0, el2)
                  vm.itemPrint.push(el2)
              }
            } else {
              vm.itemObat.push(el1);
              vm.itemPrint.push(el1);
            }
          }
          vm.itemRekonsiliasi = vm.dataResep.data_rekonsiliasi || [];

          
          // sub resep
          const x = vm.dataResep
          vm.itemsResep = [] 
          const listRacikan = []
          const listTanpaRacikan = []
          console.log('listRacikan', listRacikan)
          //menyamakan format list
          for (let i = 0; i < x.data_racikan.length; i++) {
            const obat = x.data_racikan[i];
            if(obat.head_racikan_id){
              listRacikan.push(obat)
            }else{
              listTanpaRacikan.push(obat)
            }
          }
          listRacikan.push({ racikan: listTanpaRacikan })
          //menyamakan format tabel
          for (let i = 0; i < listRacikan.length; i++) {
            const obat = listRacikan[i];
            // vm.listHead.push({head_racikan_id: obat.head_racikan_id, nama_head: obat.no_racikan, resep_id: obat.resep_id})
            vm.itemsResep.push({
              no: i + 1,
              ...obat,
              // racikan: undefined,
              type: 'head',
            })
            for (let x = 0; x < obat.racikan.length; x++) {
              const sub = obat.racikan[x];
              vm.itemsResep.push({
                no: x + 1,
                ...sub,
                type: 'sub',
                nama_qty_obat: vm.$numberFormat(sub.qty_barang),
                nama_qty_permintaan: vm.$numberFormat(sub.qty_permintaan),
                nama_harga_sub_obat_jual: vm.$numberFormat(sub.harga_satuan_racikan, 'idr'),
                nama_tanggal_kadaluarsa_obat: sub.tanggal_kadaluarsa_racikan ? vm.$moment(sub.tanggal_kadaluarsa_racikan).format('LL') : '-',
              })
            }
          }
          console.log(vm.dataResep, 'ini data resep');
        }

        //! poli
        vm.option_poli.splice(0)
        let option_poli = await vm.$axios.post("/ms_poli/list");
        vm.option_poli =
          option_poli.data.status == 200
            ? option_poli.data.data.map((x) => {
                return { ...x, value: x.ms_poli_id, text: x.nama_poli };
              })
            : [];
        //! tenaga medis
        vm.option_tenaga_medis.splice(0)
        let option_tenaga_medis = await vm.$axios.post("/tenaga_medis/list");
        vm.option_tenaga_medis =
          option_tenaga_medis.data.status == 200
            ? option_tenaga_medis.data.data.map((x) => {
                return { ...x, value: x.tenaga_medis_id, text: x.nama_tenaga_medis };
              })
            : [];
        
        //! obat by apotek 
        vm.option_obat.splice(0)
        let x = {  ms_gudang_id: vm.dataResep.ms_gudang_id, }
        let option_obat = await vm.$axios.post("/stock/list_stock_by_komposisi", x);
        vm.listBarang = option_obat.data.status == 200
            ? option_obat.data.data.map((x) => {
                return { ...x, value: x.ms_barang_id, text: x.nama_barang };
              })
            : [];
        vm.option_obat =
          option_obat.data.status == 200
            ? option_obat.data.data.map((x) => {
                return { ...x, value: x.ms_barang_id, text: x.nama_barang };
              })
            : [];

        //! komposisi
        vm.option_komposisi.splice(0)
        let option_komposisi = await vm.$axios.post("/ms_komposisi_barang/list");
        vm.listKomposisi = option_komposisi.data.status == 200
            ? option_komposisi.data.data.map((x) => {
                return { ...x, value: x.ms_komposisi_barang_id, text: x.nama_komposisi };
              })
            : [];
        vm.option_komposisi =
          option_komposisi.data.status == 200
            ? option_komposisi.data.data.map((x) => {
                return { ...x, value: x.ms_komposisi_barang_id, text: x.nama_komposisi };
              })
            : [];

        if (vm.dataResep.apoteker_id) {
          vm.dataResep.obj_apoteker = vm.option_tenaga_medis.find(
            (o) => o.tenaga_medis_id == vm.dataResep.apoteker_id
          );
        }
        if (vm.dataResep.asisten_apoteker_id) {
          vm.dataResep.obj_asisten_apoteker = vm.option_tenaga_medis.find(
            (o) => o.tenaga_medis_id == vm.dataResep.asisten_apoteker_id
          );
        }

        vm.createDataPrint()
        // console.log(vm.dataResep, 'ini data resep');
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      } finally {
        vm.showOverlay = false;
      }
    },
    checkUsia(val) {
      let a = this.$moment();
      let b = this.$moment(val);

      let diffDuration = this.$moment.duration(a.diff(b));
      console.log(`${diffDuration.years()} tahun ${diffDuration.months()} bulan ${diffDuration.days()} hari`);
      return `${diffDuration.years()} tahun ${diffDuration.months()} bulan ${diffDuration.days()} hari`;
    },
    async mulaiResep() {
      const vm = this;
      // console.log(vm.checkUsia(vm.dataResep.tanggal_lahir));
      if (!_.isEmpty(vm.dataResep.obj_apoteker)) {
        vm.dataResep.apoteker_id = vm.dataResep.obj_apoteker.tenaga_medis_id;
        if (!_.isEmpty(vm.dataResep.obj_asisten_apoteker)) {
          vm.dataResep.asisten_apoteker_id =
            vm.dataResep.obj_asisten_apoteker.tenaga_medis_id;
        }
      } else {
        vm.triggerAlert({
          variant: "danger",
          msg: "SILAHKAN PILIH APOTEKER",
          showing: true,
        });
        return;
      }
      let req = {
        resep_id: vm.dataResep.resep_id,
        kunjungan_id: vm.dataResep.kunjungan_id,
        ms_gudang_id: vm.dataResep.ms_gudang_id,
        pendaftaran_id: vm.dataResep.pendaftaran_id,
        asuransi_pasien: vm.dataResep.asuransi_pasien,
        status_resep: 2,
        tanggal_mulai_dibuat: vm.$moment(),
        apoteker_id: vm.dataResep.apoteker_id,
        asisten_apoteker_id: vm.dataResep.asisten_apoteker_id,
        usia_pasien: vm.checkUsia(vm.dataResep.tanggal_lahir),
      };
      try {
        // console.log(req);
        const res = await vm.$axios.post(`/pelayanan_resep/start_pelayanan_resep`, req);
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.triggerAlert({
            variant: "success",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
          vm.getDatas();
          vm.resetData();
        } else {
          vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    async selesaiResep(status_resep) {
      const vm = this;
      let x = {
        resep_id: this.dataResep.resep_id,
        status_resep: status_resep,
        tanggal_selesai_dibuat: vm.$moment(new Date)
      };
      try {
        const res = await vm.$axios.post(`/pelayanan_resep/finish_pelayanan_resep`, x);
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.triggerAlert({
            variant: "success",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
          vm.getDatas();
          vm.resetData();
        } else {
          vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    async getDetailRacikan(val) {
      const vm = this;
      console.log("detail racikan", val);
      try {
        const res = await vm.$axios("/racikan/details_by_id/" + val.racikan_id);
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.is_data = res.data.data[0];
          console.log(vm.is_data);
          if (vm.is_data) {
            vm.$bvModal.show("modal-edit-detail-racikan");
          } else {
            vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper("no data"),
            showing: true,
          });
          }
        } else {
          vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    selectData(val) {
      console.log(val);
    },
    // getDetailBatch(val) {
    //   const vm = this;
    //   console.log(val);
    // },
    resetData() {
      console.log("reset");
    },
    filteredOptions(item) {
      return item.filter((i) => {
        if (i.nama_jenis_tenaga_medis) {
          return i.nama_jenis_tenaga_medis.toLowerCase().includes("apotek");
        }
      });
    },
    tambahRacikan() {
      this.is_data = {}
      this.is_data.resep_id = this.dataResep.resep_id,
      this.is_data.rm_id = this.dataResep.rm_id
      this.pool_alergi = this.dataResep.data_alergi
      // console.log(this.is_data);
      // console.log(this.option_komposisi);
      // console.log(this.option_obat);
      this.$bvModal.show("modal-tambah-resep")
    },
    openModalInfoBatch(ev) {
      const vm = this
      // console.log(">>>>>>>>>>>>>", ev);
      vm.is_data = ev
      vm.$bvModal.show("modal-info-batch")
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    async printSederhana(x){
      // if(x == this.data_print){
      //   await this.$htmlToPaper("resep_sederhana");
      // }
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      // console.log(x);
      await this.$htmlToPaper(x, options);
      
    },
    async printLabelKemasan(x){
      // if(x == this.data_print){
      //   await this.$htmlToPaper("resep_sederhana");
      // }
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-58mm.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      // console.log(x);
      await this.$htmlToPaper(x, options);
    },
    createDataPrint() {
      const vm = this
      vm.printDataRoll = { ...vm.dataResep }
      // vm.printDataRoll.nama_lengkap = vm.dataKunjungan.nama_lengkap
      // vm.printDataRoll.nik = vm.dataKunjungan.nik
      // vm.printDataRoll.no_asuransi_lain = vm.dataKunjungan.no_asuransi_lain
      // vm.printDataRoll.alamat_sekarang = vm.dataKunjungan.alamat_sekarang
      // vm.printDataRoll.tempat_lahir = vm.dataKunjungan.tempat_lahir
      // vm.printDataRoll.tanggal_lahir = vm.dataKunjungan.tanggal_lahir
      // vm.printDataRoll.umur = vm.dataKunjungan.umur
      // vm.printDataRoll.berat_badan = vm.dataKunjungan.berat_badan
      // vm.printDataRoll.tinggi_badan = vm.dataKunjungan.tinggi_badan
      // vm.printDataRoll.tanggal_daftar = vm.dataKunjungan.tanggal_daftar
      // vm.printDataRoll.nama_poli = vm.dataKunjungan.nama_poli

      vm.printDataRoll.itemObat = vm.itemObat
      // // vm.printDataRoll.itemResep = vm.itemResep
      
      // // dari getDataHead
      vm.printDataRoll.itemResep = vm.penampungPrint
    }
  },
};
</script>

<style>
/* #table-detail-resep .table-color th {
  background-color: #D52F65 !important ;
  color: #fff !important;
  border-top-left-radius: 8px !important;
  font-weight: 600;
} */

.hidden-header {
  display: none;
}
.cetak-label {
  background-color: rgb(11, 14, 168) !important;
  color: #FFFFFF;
}
.cetak-rol-btn {
  background-color: #9C4098 !important;
  color: #FFFFFF;
}
.cetak-btn {
  background-color: #F2994A !important;
  color: #FFFFFF;
}
/* #table-detail-resep .table-color th:first-child {
  border-top-left-radius: 8px;
}
#table-detail-resep .table-color th:last-child {
  border-top-right-radius: 8px;
} */
</style>
