<template>
  <div>
    <b-modal
      id="modal-edit-detail-racikan"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-container v-if="is_data">
        <b-row>
          <b-col>
            <b-form-group label-cols-md="3">
              <template v-slot:label> Nama Racikan </template>
              <b-form-input
                readonly
                v-model="is_data.no_racikan"
                placeholder="Non Racikan"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Jumlah Permintaan <span class="text-danger">*</span>
              </template>
              <b-form-input
                type="number"
                disabled
                v-model="is_data.qty_permintaan"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Nama Obat <span class="text-danger">*</span>
              </template>
              <b-form-input
                readonly
                v-model="is_data.nama_barang"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Jumlah Obat <span class="text-danger">*</span>
              </template>
              <b-form-input v-model="is_data.qty_barang" type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label> Signa <span class="text-danger">*</span> </template>
              <b-form-select
                v-model="is_data.signa_racikan"
                :options="$store.state.data_static.signa"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Aturan Pakai <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="is_data.aturan_pakai_racikan"
                :options="$store.state.data_static.aturan_pakai"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Indikasi <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="is_data.indikasi"
                type="text"
                placeholder="Tambah Indikasi"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Keterangan <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                v-model="is_data.keterangan_racikan"
                rows="3"
                placeholder="Tambah keterangan"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5>Detail Obat</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="m-0 p-3" v-for="value in is_data.data_stock" :key="value.id">
              <b-row class="m-0 p-0">
                <b-col>
                  <b-row>
                    <b-col cols="5">
                      <h6 class="m-0 p-0">Kode Batch</h6>
                    </b-col>
                    <b-col>
                      <h6 class="m-0 p-0">: {{ value.kode_batch }}</h6>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="3">
                  <b-row>
                    <b-col cols="5">
                      <h6 class="m-0 p-0">Jumlah</h6>
                    </b-col>
                    <b-col >
                      <h6 class="m-0 p-0">: {{ value.qty_obat }}</h6>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4">
                  <b-row>
                    <b-col cols="5">
                      <h6 class="m-0 p-0">Kadaluarsa</h6>
                    </b-col>
                    <b-col >
                      <h6 class="m-0 p-0">
                        :
                        {{ $moment(value.tanggal_kadaluarsa).format("LL") }}
                      </h6>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-detail-racikan')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEditDetailRacikan",
  props: ["fileName", "data_edit"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
      qty_awal: null,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.is_data.$invalid;
    },
    isDirty() {
      return this.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      this.qty_awal = this.is_data.qty_barang
      console.log(this.is_data, 'uhuyyyyy');
    },
  },

  // mixins: [validationMixin],
  // validations: {
  //   is_data: {
  //     username: { required },
  //     role: { required },
  //     nama: { required },
  //     alamat: { required },
  //     puskesmas_id: { required },
  //   },
  // },
  methods: {
    // checkIfValid(fieldName) {
    //   const field = this.is_data[fieldName];
    //   if (!field.$dirty) {
    //     return null;
    //   }
    //   return !(field.$invalid || field === "");
    // },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      let data_edited = {
        id: vm.is_data.racikan_id,
        qty_barang: vm.is_data.qty_barang,
        signa_racikan: vm.is_data.signa_racikan,
        aturan_pakai_racikan: vm.is_data.aturan_pakai_racikan,
        indikasi: vm.is_data.indikasi,
        keterangan_racikan: vm.is_data.keterangan_racikan,
        resep_id: vm.is_data.resep_id,
        ms_barang_id: vm.is_data.ms_barang_id
      }
      vm.$axios
        .post(`/racikan/update_racikan_stock`, data_edited)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            // vm.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-detail-racikan");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      // this.$reset();
      this.is_data = {
        username: null,
        role: null,
        nama: null,
        alamat: null,
        puskesmas_id: null,
      };
    },
  },
};
</script>
