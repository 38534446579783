<template>
  <span>
    <b-button
      class="ml-2"
      @click="printGeneralConsent('distribusi_makanan')"
      :disabled="is_disabled"
      variant="success"
    >
      {{ nama_tombol }}
    </b-button>
    <!-- PRINT DISTRIBUSI MAKANAN -->
    <div style="display: none" id="distribusi_makanan">
      <section class="content-print paper-80" v-if="printResep">
        <div class="sheet">
          <div class="w-100">
            <h6
              style="
                text-align: center;
                font-weight: bold;
                margin-bottom: 0;
                font-size: 8pt;
              "
            >
              <!-- PEMERINTAH KABUPATEN/KOTA <span>NAMA KABKOT</span> -->
              PEMERINTAH {{ $store.state.puskesmas.nama_kota }}
            </h6>
            <h6
              style="
                text-align: center;
                font-weight: bold;
                margin-bottom: 0;
                font-size: 8pt;
              "
            >
              <span>DINAS KESEHATAN</span>
            </h6>
            <h6
              style="
                text-align: center;
                font-weight: bold;
                margin-bottom: 0;
                font-size: 8pt;
              "
            >
              <!-- PUSKESMAS <span>NAMA PUSKESMAS</span> -->
              {{ $store.state.puskesmas.nama_puskesmas }}
            </h6>
            <h6 style="text-align: center; font-weight: normal; font-size: 6pt">
              <!-- <span>
                Jl. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta,
                nulla
              </span> -->
              {{ $store.state.puskesmas.alamat_puskesmas }}
            </h6>
            <!-- <h6 style="text-align: center; font-weight: normal; font-size: 6pt">
              <span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span>
            </h6> -->
          </div>

          <div class="w-100">
            <div class="line" style="margin-top: 2.5mm"></div>
            <h6
              style="
                margin: 2.5mm 0;
                font-size: 8pt;
                text-align: center;
                font-weight: bold;
              "
            >
              <sup>*</sup> RESEP <sup>*</sup>
            </h6>
            <div class="line" style="margin-bottom: 2.5mm"></div>
          </div>

          <div style="width: 100%">
            <table class="custom-table" border="0">
              <tbody>
                <tr>
                  <td style="width: 20mm; font-size: 8pt">Nama</td>
                  <td style="font-size: 8pt; width: 2.5mm">:</td>
                  <td style="font-size: 8pt">{{ printResep.nama_lengkap || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">No. eRM</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_rm || "-" }}</td>
                </tr>

                <!-- <tr>
                  <td style="font-size: 8pt">No. Dok RM</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_rm }}</td>
                </tr> -->

                <!-- <tr>
                  <td style="font-size: 8pt">No. RM Lama</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_rm }}</td>
                </tr> -->

                <tr>
                  <td style="font-size: 8pt">NIK</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.nik || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Asuransi</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.asuransi || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Alamat</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.alamat_sekarang || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Tempat / Tanggal Lahir</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.tempat_lahir }} / {{ $moment(printResep.tanggal_lahir).format("LL") }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Umur</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">
                    <span v-if="printResep.usia_tahun">{{ printResep.usia_tahun }} Tahun</span>
                    <span v-if="printResep.usia_bulan">{{ printResep.usia_bulan }} Bulan</span>
                    <span v-if="printResep.usia_hari">{{ printResep.usia_hari }} Hari</span>
                    <span v-if="printResep.usia_pasien">{{ printResep.usia_pasien }}</span>
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Berat Badan</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.berat_badan || "-" }}</td>
                </tr>
                
                <tr>
                  <td style="font-size: 8pt">Tinggi Badan</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.tinggi_badan || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Dokter</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.nama_dokter || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">SIP</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_sip || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Poli</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.nama_poli || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">No. Antrean</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_antrian || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">No. Resep</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ printResep.no_resep || "-" }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">Tanggal Pelayanan</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ $moment(printResep.tanggal_daftar).format("LLL") }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">ICD</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">
                    <ul style="padding-left: 1.5rem;">
                      <li v-for="item in printResep.data_diagnosa">{{ item.kd_diag }}</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100">
            <div class="line" style="margin-top: 2.5mm"></div>
            <div
              style="
                width: 100%;
                height: 15mm;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 80%;
                  height: 10mm;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid black;
                "
              >
                <!-- BARCODE -->
                <barcode :value="printResep.no_resep" :height="30" :displayValue="false">
                    **{{ printResep.no_resep }}**
                  </barcode>
              </div>
            </div>
            <div class="line" style="margin-bottom: 2.5mm"></div>
          </div>

          <div class="w-100">
            <div style="width: 100%; border: 1px solid black; border-style: dashed"></div>
            <h6 style="font-size: 8pt">-</h6>
          </div>

          <div style="width: 100%; margin-top: 2.5mm">
            <table class="custom-table" border="0" v-for="(item, index) in printResep.itemObat" :key="item.racikan_id">
              <tbody>
                <tr>
                  <td style="font-size: 8pt; width: 2.5mm">{{ index + 1 }}</td>
                  <td style="font-size: 8pt; width: 20mm">JObats</td>
                  <td style="font-size: 8pt; width: 2.5mm">:</td>
                  <td style="font-size: 8pt">{{ item.nama_barang }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JQty</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ item.qty_permintaan }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JAturan Pakai</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ item.aturan_pakai_racikan }}</td>
                </tr>
                
                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JSigna</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ item.signa_racikan }}</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JKeterangan</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt">{{ item.keterangan_racikan }}</td>
                </tr>
              </tbody>
            </table>

            <!-- <table class="custom-table" border="0">
              <tbody>
                <tr>
                  <td style="font-size: 8pt; width: 2.5mm">2.</td>
                  <td style="font-size: 8pt; width: 20mm">JObats</td>
                  <td style="font-size: 8pt; width: 2.5mm">:</td>
                  <td style="font-size: 8pt"></td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JQty</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt"></td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JSigna</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt"></td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JAturan Pakai</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt"></td>
                </tr>

                <tr>
                  <td style="font-size: 8pt">&nbsp;</td>
                  <td style="font-size: 8pt">JKeterangan</td>
                  <td style="font-size: 8pt">:</td>
                  <td style="font-size: 8pt"></td>
                </tr>
              </tbody>
            </table> -->
          </div>

          <div v-for="(value, index) in printResep.itemResep">
            <div class="w-100" style="margin-top: 2.5mm">
              <div
                style="
                  width: 100%;
                  border: 1px solid black;
                  border-style: dashed;
                  position: relative;
                "
              >
                <div
                  style="
                    position: absolute;
                    left: 0;
                    width: auto;
                    height: 7mm;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  "
                >
                  <h6 style="font-size: 8pt">{{ value.no_racikan || "-" }}</h6>
                </div>

                <div
                  style="
                    position: absolute;
                    left: 20mm;
                    width: 15mm;
                    height: 7mm;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  "
                >
                  <h6 style="font-size: 8pt">Racikan</h6>
                </div>
              </div>
            </div>

            <div class="w-100" style="margin-top: 2.5mm">
              <h6 style="font-size: 8pt">-</h6>
            </div>

            <div style="width: 100%; margin-top: 2.5mm">
              <table class="custom-table" border="0">
                <tbody>
                  <tr>
                    <td style="font-size: 8pt; width: 24.5mm">JPermintaan</td>
                    <td style="font-size: 8pt; width: 2.5mm">:</td>
                    <td style="font-size: 8pt">{{ value.jumlah_bungkus || "-" }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt">JIndikasi</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt">{{ value.indikasi_head_racikan || "-" }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="custom-table" border="0" v-for="(data, nomor) in value.racikan">
                <tbody>
                  <tr>
                    <td style="font-size: 8pt; width: 2.5mm">{{ nomor + 1 }}.</td>
                    <td style="font-size: 8pt; width: 20mm">JObats</td>
                    <td style="font-size: 8pt; width: 2.5mm">:</td>
                    <td style="font-size: 8pt">{{ data.nama_obat }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt">&nbsp;</td>
                    <td style="font-size: 8pt">JQty</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt">{{ data.qty_permintaan }}</td>
                  </tr>
                  
                  <tr>
                    <td style="font-size: 8pt">&nbsp;</td>
                    <td style="font-size: 8pt">JAturan Pakai</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt">{{ data.aturan_pakai_racikan }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt">&nbsp;</td>
                    <td style="font-size: 8pt">JSigna</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt">{{ data.signa_racikan }}</td>
                  </tr>
                  
                  <tr>
                    <td style="font-size: 8pt">&nbsp;</td>
                    <td style="font-size: 8pt">JKeterangan</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt">{{ data.keterangan_racikan }}</td>
                  </tr>
                </tbody>
              </table>

              <!-- <table class="custom-table" border="0">
                <tbody>
                  <tr>
                    <td style="font-size: 8pt; width: 2.5mm">2.</td>
                    <td style="font-size: 8pt; width: 20mm">JObats</td>
                    <td style="font-size: 8pt; width: 2.5mm">:</td>
                    <td style="font-size: 8pt"></td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt">&nbsp;</td>
                    <td style="font-size: 8pt">JQty</td>
                    <td style="font-size: 8pt">:</td>
                    <td style="font-size: 8pt"></td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>

          <div class="w-100" style="margin-top: 2.5mm">
            <h6 style="font-size: 8pt">Date {{ $moment(printResep.tanggal_daftar).format("LLL") }}</h6>
            <h6 style="font-size: 8pt">Telp. :</h6>
            <h6 style="font-size: 8pt">SIP.dr/bid :</h6>
          </div>

          <div class="w-100" style="margin-top: 2.5mm">
            <table class="custom-table" border="1">
              <thead>
                <tr>
                  <th style="font-size: 8pt; text-align: center">Rekonsiliasi Obat</th>
                  <th style="font-size: 8pt; text-align: center">Stop</th>
                  <th style="font-size: 8pt; text-align: center">Lanjut</th>
                  <th style="font-size: 8pt; text-align: center">Aturan Pakai</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="printResep.itemRekonsiliasi">
                  <tr v-for="item in printResep.itemRekonsiliasi">
                    <td style="font-size: 8pt; text-align: center">{{ item.nama_obat }}</td>
                    <td style="font-size: 8pt; text-align: center" v-if="item.status_pemberian_obat.include('lanjut')">&#10004;</td>
                    <td style="font-size: 8pt; text-align: center" v-else>-</td>
                    <td style="font-size: 8pt; text-align: center" v-if="item.status_pemberian_obat.include('stop')">&#10004;</td>
                    <td style="font-size: 8pt; text-align: center" v-else>-</td>
                    <td style="font-size: 8pt; text-align: center">{{ item.perubahan_aturan_pakai }}</td>
                  </tr>
                </template>
                
                <template v-else>
                  <tr>
                    <td colspan="4" style="font-size: 8pt; text-align: center">-</td>
                    <!-- <td style="font-size: 8pt; text-align: center">&#10004;</td>
                    <td style="font-size: 8pt; text-align: center">-</td>
                    <td style="font-size: 8pt; text-align: center">-</td> -->
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div style="margin-top: 2.5mm; width: 100%">
            <table class="custom-table" border="1">
              <thead>
                <tr>
                  <th style="font-size: 8pt; text-align: center; width: 45mm">
                    Pengkajian Resep
                  </th>
                  <th style="font-size: 8pt; text-align: center">Ya</th>
                  <th style="font-size: 8pt; text-align: center">Tidak</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="font-size: 8pt; text-align: left">Adanya Admisi</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td colspan="3" style="font-size: 8pt; text-align: left">
                    Kelengkapan Penulisan Resep
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">
                    <ul style="padding-left: 15px">
                      <li>Nama Dokter Penulis Resep</li>
                      <li>Tanggal Resep</li>
                      <li>SIP Dokter Penulis Resep</li>
                      <li>Nama, TTL umur Pasien</li>
                    </ul>
                  </td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">
                    Kejelasan Tulisan Resep
                  </td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">BB Untuk Pasien Anak</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td colspan="3" style="font-size: 8pt; text-align: left">Farmasetis</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">
                    <ul style="padding-left: 15px">
                      <li>Nama, Bentuk Kekuatan, Jumlah Obat</li>
                      <li>Signa/Aturan Pakai</li>
                    </ul>
                  </td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td colspan="3" style="font-size: 8pt; text-align: left">
                    Farmasi Klinik :
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Tepat Obat</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Tepat Dosis</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Tepat Rute</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Tepat Waktu</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Duplikat</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Alergi Obat</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left">Kontra Indikasi</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                  <td style="font-size: 8pt; text-align: center">-</td>
                </tr>

                <tr>
                  <td colspan="3" style="font-size: 8pt; text-align: left">
                    Keterangan Tindak Lanjut :
                  </td>
                </tr>

                <tr>
                  <td colspan="3" style="font-size: 8pt; text-align: left">
                    Edukasi dan Pemberian Informasi Obat
                  </td>
                </tr>

                <tr>
                  <td colspan="3">
                    <table class="custom-table">
                      <tbody>
                        <tr>
                          <td style="font-size: 8pt; text-align: left; width: 33.33%">
                            Nama Obat
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Penyimpanan
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Efek Samping
                          </td>
                        </tr>

                        <tr>
                          <td style="font-size: 8pt; text-align: left; width: 33.33%">
                            Sediaan
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Indikasi
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Interaksi
                          </td>
                        </tr>

                        <tr>
                          <td style="font-size: 8pt; text-align: left; width: 33.33%">
                            Dosis
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Kontra Indikasi
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            Cara Pakai
                          </td>
                        </tr>

                        <tr>
                          <td style="font-size: 8pt; text-align: left; width: 33.33%">
                            Stabilitas
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            &nbsp;
                          </td>
                          <td style="font-size: 8pt; text-align: center; width: 33.33%">
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="margin-top: 5mm; width: 100%">
            <table class="custom-table" border="1">
              <tbody>
                <tr>
                  <td style="font-size: 8pt; text-align: left" colspan="3">
                    Paraf Petugas
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left; width: 50%; padding: 5mm 0;">
                    Dispensing
                  </td>
                  <td style="font-size: 8pt; text-align: center; width: 50%; padding: 5mm 0;">
                    -
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left; width: 50%; padding: 5mm 0;">
                    Penyerahan Obat
                  </td>
                  <td style="font-size: 8pt; text-align: center; width: 50%; padding: 5mm 0;">
                    -
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left" colspan="3">
                    Paraf Pasien
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left; width: 50%; padding: 5mm 0;">
                    Penerimaan Obat
                  </td>
                  <td style="font-size: 8pt; text-align: center; width: 50%; padding: 5mm 0;">
                    -
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left; width: 50%; padding: 5mm 0;">
                    Edukasi
                  </td>
                  <td style="font-size: 8pt; text-align: center; width: 50%; padding: 5mm 0;">
                    -
                  </td>
                </tr>

                <tr>
                  <td style="font-size: 8pt; text-align: left" colspan="3">
                    No. Telp. Pasien : 00000000000
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </span>
</template>

<script>
import VueBarcode from 'vue-barcode';
export default {
  components: {
    'barcode': VueBarcode
  },
  props: ["nama_tombol", "is_disabled", "printResep"],
  data() {
    return {
      busy: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    async printGeneralConsent(val) {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/style-paper-80mm.css"],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      // await this.$htmlToPaper(val, options);
      await this.$htmlToPaper(val, options);
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
